<template>
  <div>
    <section>
      <Hero>
        <div class="container">
          <HeadText :titletext="`Bestätigung Ihres neuen Benutzerzugangs für ${subsiteDisplayName}`" :subtext="''"></HeadText>
        </div>

        <div class="container mb-5" v-if="!this.$route.query.token">
          <div class="row justify-content-center pt-3">
            <div class="alert alert-warning" role="alert">
              <p>
                Der von Ihnen verwendete Link ist ungültig.
              </p>
            </div>
          </div>
        </div>
        
        <div class="container mb-5" v-else>

          <b-form @submit="submitForm">

            <div class="row justify-content-start pt-sm-4">
              <div class="col-sm-12 col-lg-11 text-left">
                <p>Bitte wählen Sie für Ihren neuen Benutzerzugang ein Passwort.</p>
              </div>
            </div>

            <div class="row justify-content-start pt-sm-4">
              <div class="col-sm-6 col-lg-5 text-left">
                <b-form-group
                    id="input-group-5"
                    label-for="passwordInputField"
                    class="required"
                >
                  <label id="passwordInputField-label" for="passwordInputField">
                    <span>Passwort <i id="passwordInputField-label-hint" class="fas fa-question-circle"></i></span>
                  </label>
                  <b-form-input
                      id="passwordInputField"
                      type="password"
                      aria-describedby="passwordInputField-live-feedback"
                      v-model.trim="$v.form.password.$model"
                      @keyup="preCheckPassword"
                      :state="validateState('password')"
                  ></b-form-input>
                  <b-form-invalid-feedback id="passwordInputField-live-feedback">
                    <span v-if="!$v.form.password.$model">Bitte geben Sie Ihr Passwort an.</span>
                    <span v-else-if="!$v.form.guidelinesFulfilled === true">Das Passwort entspricht nicht den Mindestanforderungen.</span>
                    <span v-else-if="!$v.form.sufficient === true">Das Passwort ist zu schwach.</span>
                    <span v-else>Das Passwort ist ungenügend.</span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
              <div class="col-sm-6 col-lg-5 text-left">
                <b-form-group
                    id="input-group-6"
                    label="Passwort (wiederholen)"
                    label-for="passwordRepeatInputField"
                    class="required"
                >
                  <b-form-input
                      id="passwordRepeatInputField"
                      type="password"
                      aria-describedby="passwordRepeatInputField-live-feedback"
                      v-model.trim="$v.form.passwordRepeat.$model"
                      :state="validateState('passwordRepeat')"
                  ></b-form-input>
                  <b-form-invalid-feedback id="passwordRepeatInputField-live-feedback">
                    <span v-if="!$v.form.passwordRepeat.$model">Bitte geben Sie Ihr Passwort wiederholt ein.</span>
                    <span v-else>Passwort und Passwortwiederholung stimmen nicht überein.</span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
            </div>

            <div class="row justify-content-start pt-sm-4">
              <div class="text-left p-3">
                <b-form-group
                    id="checkbox-group-7"
                >
                  <b-form-checkbox
                      class="mb-3"
                      id="agbField"
                      v-model.trim="$v.form.agbChecked.$model"
                      :state="validateState('agbChecked')"
                  >
                    Ich bestätige, dass ich die <FdbAgbLink></FdbAgbLink> heruntergeladen und gelesen habe. <span class="text-color-dark">*</span>
                  </b-form-checkbox>
                  <b-form-checkbox
                      class="mb-3"
                      id="dataProtectionField"
                      v-model.trim="$v.form.dataProtectionChecked.$model"
                      :state="validateState('dataProtectionChecked')"
                  >
                    Ich bestätige, dass ich die <FdbDataProtectionRulesLink></FdbDataProtectionRulesLink> heruntergeladen und gelesen habe. <span class="text-color-dark">*</span>
                  </b-form-checkbox>
                  <b-form-checkbox class="mb-3" id="newsletterSubscriptionField" v-model="form.newsletterSubscription">Ich möchte die täglichen Verkaufsangebote (Käufermail) abonnieren.</b-form-checkbox>
                </b-form-group>
              </div>
            </div>

            <div class="row pt-3 pl-3 pr-3" v-if="validationResults && validationResults.length > 0">
              <div class="text-left w-100">
                <b-alert show variant="danger" v-for="validationResult in validationResults" :key="validationResult">
                  {{validationResult}}
                </b-alert>
              </div>
            </div>

            <div class="row justify-content-center pt-sm-4" v-if="this.getRegistrationErrorStatus">
              <div class="alert alert-warning" role="alert">
                <p>
                  {{ this.getRegistrationErrorStatus }}
                </p>
                <p v-if="this.getRegistrationValidationErrors && this.getRegistrationValidationErrors.length > 0">
                  {{ this.mapErrors }}
                </p>
              </div>
            </div>

            <div class="row justify-content-center pt-sm-4" v-if="this.isRegistrationWaiting">
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Die Daten werden übernommen...</strong>
              </div>
            </div>
            <div class="row justify-content-center pt-sm-4" v-if="this.isRegistrationSuccess">
              <div class="alert alert-info" role="alert">
                <p>
                  Vielen Dank! Die Datenübername wurde bestätigt.
                </p>
              </div>
            </div>
            <div class="row justify-content-center pt-sm-4" v-if="this.isRegistrationSuccess">
              <div class="col-sm-12">
                <p>
                  Klicken Sie <span class="text-uppercase"><router-link :to="{ name: 'login', params: { loginName: this.getRegisteredData }}">hier</router-link></span>
                  um sich mit Ihrem Benutzerzugang '{{this.getRegisteredData}}' anzumelden.
                </p>
              </div>
            </div>

            <div class="row justify-content-end pt-sm-4">
              <div class="text-right mr-3">
                <b-button
                    id="person-form-submit"
                    type="submit"
                    variant="default"
                    :disabled="this.isRegistrationWaiting"
                >
                  Jetzt Bestätigen
                </b-button>
              </div>
            </div>
            <b-tooltip v-if="hintHtml" target="passwordInputField-label-hint" triggers="hover" placement="topright">
              <span v-html="hintHtml"></span>
            </b-tooltip>
          </b-form>
          
        </div>
      </Hero>
    </section>
  </div>
</template>

<script>
import Hero from "@/components/HeroPicture";
import HeadText from "@/components/HeadText";
import {mapGetters} from "vuex";
import FdbAgbLink from "@/components/FdbAgbLink";
import FdbDataProtectionRulesLink from "@/components/FdbDataProtectionRulesLink";
import testPassword from "@/mixins/testPassword";

import { validationMixin } from 'vuelidate'
import {required, sameAs} from "vuelidate/lib/validators";
import axios from "axios";

const strengthOk = (value, vm) => {
  return vm.sufficient === true
}
const minRequirementsOk = (value, vm) => {
  return vm.guidelinesFulfilled === true
}

export default {
  name: "ConfirmLoginCreation",
  components: {Hero, HeadText, FdbAgbLink, FdbDataProtectionRulesLink},
  metaInfo() {
    return {
      title: this.getClientConfig('name') + ' | ' + this.getClientConfig('title'),
      meta: [
        {
          name: 'description',
          content: 'Der Marktplatz für geschlossene Fonds. Wir bieten freien und institutionellen Vertriebsgesellschaften das führende Service- und Plattformangebot für die Auswahl, den Vertrieb, die Abwicklung und die Bestandsverwaltung geschlossener Fonds in Deutschland und Österreich.',
        },
        { name: 'robots', content: "noindex, nofollow", vmid: 'robots' },
        { name: 'googlebot', content: "noindex, nofollow, max-snippet:-1, max-image-preview:large, max-video-preview:-1", vmid: 'googlebot' },
        { name: 'bingbot', content: "noindex, nofollow, max-snippet:-1, max-image-preview:large, max-video-preview:-1", vmid: 'bingbot' },
      ],
    }
  },
  data() {
    return {
      error: null,
      hintHtml: null,
      form: {
        password: null,
        passwordRepeat: null,
        agbChecked: null,
        dataProtectionChecked: null,
        newsletterSubscription: false,
        // "pseudo" fields needed for validation
        sufficient: undefined,
        guidelinesFulfilled: undefined,
      },
      passwordStrengthTest: {
        variant: "danger",
        bar_fill_percant: 0,
        pwd_strength_res: "",
        sufficient: false,
      },
      validationResults: [],
    }},
  validations() {
    return {
      form: {
        password: {
          required,
          strengthOk,
          minRequirementsOk,
        },
        passwordRepeat: {
          required,
          sameAsPassword: sameAs('password'),
        },
        agbChecked: {
          required,
        },
        dataProtectionChecked: {
          required,
        }
      },
    }},
  computed: {
    ...mapGetters('registration', ['isRegistrationWaiting','getRegistrationErrorStatus','isRegistrationSuccess',
      'getRegistrationValidationErrors','getRegisteredData']),
    subsiteDisplayName() {
      return this.isMeinHandel ? 'Mein Handel' : 'Mein Zweitmarkt'
    },
    isMeinHandel() {
      return this.$store.getters["getSubsite"] === 'meinhandel';
    },
    mapErrors() {
      let errors = this.getRegistrationValidationErrors;
      let result = '';
      let firstCode = true
      for (let errorCode of errors) {
        result = result + this.lookupErrorCode( errorCode, firstCode ) + ', ';
        firstCode = false;
      }

      return result.slice(0, result.length - 2);
    },
  },
  methods: {
    getClientConfig(path) {
      return this.$store.getters['clientConfig/config'](path)
    },
    validateState(fieldName) {
      const { $dirty, $error } = this.$v.form[fieldName];
      return $dirty ? !$error : null;
    },
    getHint() {
      axios.get( this.$store.getters.getAllEnvis.API_PASSWORD_HINT.replace('{subsite}', this.$store.getters["getSubsite"]) )
          .then( response => {
            this.hintHtml = response.data;
          } )
          .catch(error => {
            console.log(error);
            this.error = error;
          })
    },
    preCheckPassword() {
      this.passwordStrengthTest = testPassword( this.form.password );
      this.form.sufficient = this.passwordStrengthTest.sufficient ? true : undefined;
      var config = {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      };
      var subsite = null;
      if(this.$store.getters['getSubsite']) {
        subsite = this.$store.getters['getSubsite'];
      } else {
        subsite = window.location.pathname?.split('/')?.[1];
      }
      const payload = JSON.stringify( {new_password: this.form.password} );
      axios.post( this.$store.getters.getAllEnvis.API_PASSWORD_CHECK.replace('{subsite}', subsite),
          payload, config )
          .then( () => {
            this.form.guidelinesFulfilled = true;
          })
          .catch(error => {
            this.form.guidelinesFulfilled = undefined;
            if ( error.response.status !== 400 ) {
              console.log(error);
              this.error = error;
            }
          })
    },
    lookupErrorCode( errorCode, firstCode ) {
      if ( 'E4000' === errorCode ) return (firstCode ? 'D' : 'd') + 'ieser Benutzername existiert bereits'
      if ( 'E4001' === errorCode ) return (firstCode ? 'E' : 'e') + 'ine Registrierung für diese E-Mail-Adresse existiert bereits'
      if ( 'E4002' === errorCode ) return (firstCode ? 'U' : 'u') + 'nbekannter Datensatztyp'
      if ( 'E4004' === errorCode ) return (firstCode ? 'U' : 'u') + 'ngültige E-Mail-Adresse'
      if ( 'E4202' === errorCode ) return (firstCode ? 'U' : 'u') + 'ngültiges Passwort'
      if ( 'E4005' === errorCode ) return (firstCode ? 'D' : 'd') + 'ie angegebene E-Mail-Adresse ist zu lang'
      if ( 'E4203' === errorCode ) return '\'Anrede\' muss angegeben werden'
      if ( 'E4021' === errorCode ) return '\'Vorname\' muss angegeben werden'
      if ( 'E4022' === errorCode ) return '\'Nachname\' muss angegeben werden'
      if ( 'E4040' === errorCode ) return '\'Firma\' muss angegeben werden'
      if ( 'E4108' === errorCode ) return '\'Firma\' ist zu lang'
      if ( 'E4109' === errorCode ) return '\'Vorname\' ist zu lang'
      if ( 'E4110' === errorCode ) return '\'Nachname\' ist zu lang'

      if ( 'E4300' === errorCode ) return 'Der Token ist ungültig'
      if ( 'E4301' === errorCode ) return 'Unbekannter Mandant'
      if ( 'E4302' === errorCode ) return 'Die Registrierung ist abgelaufen'
      if ( 'E4303' === errorCode ) return 'Die Registrierung wird bereits bearbeitet'
      if ( 'E5000' === errorCode ) return 'Die Registrierung konnte nicht gespeichert werden'
      if ( 'E5001' === errorCode ) return 'Ihr Zugang wurde angelegt. Daten zur Einstimmung zu den AGB und den Datenschutzbestimmungen konnten nicht gespeichert werden. Bitte setzen Sie sich mit uns in Verbindung.'
      return 'Unbekannter Fehlercode ' + errorCode;
    },
    submitForm(event) {
      event.preventDefault();
      this.$v.$touch();
      if (this.$v.form.$anyError) {
        this.submitInProgress = false;
        return;
      }
      this.submitLoginCreationConfirmation();
    },
    submitLoginCreationConfirmation() {
      const payload = {
        token : this.$route.query.token,
        password: this.form.password,
        newsletterSubscription: this.form.newsletterSubscription,
      }
      this.$store.dispatch('registration/postConfirmLoginCreation',payload);
    },
  },
  beforeRouteEnter(to,from,next) {
    next(vm => {
      vm.$store.dispatch('registration/resetState');
    })
  },
  created() {
    this.getHint();
  },
  mixins: [validationMixin],
}
</script>

<style scoped>

</style>